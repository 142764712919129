const isDesktopView = window.innerWidth >= 1199;
const navFooter = document.querySelector('.js-navbar-nav-footer');
let isNavbarDark = true;
const $BODY = document.querySelector('body');
const navbarStyle = document.querySelector('.js-navbar');
const navbarMain = document.querySelector('.navbar-main');
const navbarMenuToggle = document.querySelectorAll('.js-navbar-menu-toggle');
const dropdownNavFull = document.querySelector('.js-dropdown-nav-full');
const isNavbarFixed = document.querySelector('.js-navbar-fixed');
const dropdownNavFullHeight = dropdownNavFull.offsetHeight;
const $btnToggleLang = document.querySelector('.js-btn-language-selector');
const $languageSelectorWrapper = document.querySelector('.js-language-selector');

if (navbarStyle && navbarStyle.classList.contains('navbar-dark-active')) {
  isNavbarDark = true;
}

// Prevent default behavior for links with "#"
document.querySelectorAll('a[href="#"]').forEach((link) => {
  link.addEventListener('click', function (event) {
    event.preventDefault();
  });
});

// Prevent menu from closing when clicking on main-item or nav-item
document.querySelectorAll('.main-item, .nav-item').forEach((item) => {
  item.addEventListener('click', function (event) {
    event.stopPropagation();
  });
});

// Handle click event on main-item
document.querySelectorAll('.main-item').forEach((item) => {
  item.addEventListener('click', function () {
    const startLevel = parseInt(item.getAttribute('data-start'));
    const endLevel = parseInt(item.getAttribute('data-end'));
    showSection(item, startLevel, endLevel);
  });
});

// Handle click event on sub-item
document.querySelectorAll('.sub-item').forEach((item) => {
  item.addEventListener('click', function () {
    const startLevel = parseInt(item.getAttribute('data-start'));
    const endLevel = parseInt(item.getAttribute('data-end'));
    showContent(item, startLevel, endLevel);
  });
});

function showSection(element, startLevel, endLevel) {
  document.querySelectorAll('.child-items-section, .content-section').forEach((section) => {
    section.classList.remove('active');
  });

  const target = element.getAttribute('data-target');
  const targetElement = document.querySelector(target);

  if (targetElement) {
    targetElement.classList.add('active');
  }

  document.querySelectorAll('.nav-item').forEach((item) => {
    item.classList.remove('active');
  });

  element.classList.add('active');

  if (targetElement && targetElement.classList.contains('child-items-section')) {
    const firstChildItem = targetElement.querySelector('.nav-item');
    if (isDesktopView && firstChildItem) {
      firstChildItem.click();
    }
  }
}

function showContent(element, startLevel, endLevel) {
  document.querySelectorAll('.content-section').forEach((section) => {
    section.classList.remove('active');
  });

  const target = element.getAttribute('data-target');
  const targetElement = document.querySelector(target);
  if (targetElement) {
    targetElement.classList.add('active');
  }

  document.querySelectorAll('.child-items-section .nav-item').forEach((item) => {
    item.classList.remove('active');
  });
  element.classList.add('active');
}

// Handle dropdown opening/closing for js-navbar-nav-item
document.querySelectorAll('.js-navbar-nav-item').forEach((item) => {
  item.addEventListener('click', function (event) {
    if (isDesktopView) {
      closeOtherDropdowns(item);
      toggleDropdown(item);
    }
    loadImages(item);
  });
});

function toggleDropdown(item) {
  const dropdownToggle = item.querySelector('.dropdown-toggle');
  const dropdownMenu = item.querySelector('.dropdown-menu');

  const isExpanded = dropdownToggle.getAttribute('aria-expanded') === 'true';

  if (isExpanded) {
    dropdownMenu.classList.remove('show');
    dropdownToggle.classList.remove('show');
    dropdownToggle.setAttribute('aria-expanded', 'false');
    handleHideMenu();  
  } else {
    dropdownMenu.classList.add('show');
    dropdownToggle.classList.add('show');
    dropdownToggle.setAttribute('aria-expanded', 'true');
    handleShowMenu();  
  }
}

// Close other open dropdowns
function closeOtherDropdowns(currentItem) {
  document.querySelectorAll('.js-navbar-nav-item .dropdown-toggle[aria-expanded="true"]').forEach((openToggle) => {
    const openItem = openToggle.closest('.js-navbar-nav-item');

    if (openItem !== currentItem) {
      const dropdownMenu = openItem.querySelector('.dropdown-menu');
      openToggle.classList.remove('show');
      dropdownMenu.classList.remove('show');
      openToggle.setAttribute('aria-expanded', 'false');
    }
  });
}

// Load images lazily
function loadImages(item) {
  item.querySelectorAll('.menu-item img[data-src]').forEach((img) => {
    const src = img.getAttribute('data-src');
    if (src) {
      img.setAttribute('src', src);
      img.removeAttribute('data-src');
    }
  });
}

// Automatically activate the first main-item on page load
window.addEventListener('load', function () {
  const firstMainItem = document.querySelector('.main-item');
  if (firstMainItem) {
    firstMainItem.click(); // Simulate click on the first item by default
  }

  const dropdowns = document.querySelectorAll('.navbar-nav-dropdown');
  dropdowns.forEach(function (dropdown) {
    dropdown.addEventListener('click', function (event) {
      const parentListItem = dropdown.closest('.navbar-nav-item[data-level="0"]');
      if (parentListItem) {
        const dataLevelElement = parentListItem.querySelector('[data-level="1"]');
        if (dataLevelElement) {
          const navItem = dataLevelElement.querySelector('.nav-item');
          if (navItem) {
            navItem.click(); // Automatically click on the first level 1 item
          }
        }
      }
    });
  });
});

// Mobile view adjustments
if (!isDesktopView) {
  function showLevelMobile(element) {
    const target = element.getAttribute('data-target');
    document.querySelectorAll('.level').forEach((level) => {
      level.classList.remove('active');
    });
    document.querySelectorAll('.menu-item img[data-src]').forEach((img) => {
      const src = img.getAttribute('data-src');
      if (src) {
        img.setAttribute('src', src);
        img.removeAttribute('data-src');
      }
    });
    document.querySelector(target).classList.add('active');
  }

  // Handle collapse functionality
  document.querySelectorAll('[data-bs-toggle="collapse"]').forEach((trigger) => {
    trigger.addEventListener('click', function (event) {
      event.preventDefault();

      const targetSelector = trigger.getAttribute('data-bs-target');
      const targetElement = document.querySelector(targetSelector);

      if (targetElement) {
        if (targetElement.classList.contains('show')) {
          collapseSection(targetElement, trigger);
        } else {
          expandSection(targetElement, trigger);
        }

        if (targetElement.getAttribute('data-bs-parent')) {
          const parentSelector = targetElement.getAttribute('data-bs-parent');
          closeOtherSections(targetElement, parentSelector);
        }
      }
    });
  });

  function expandSection(section, trigger) {
    section.classList.add('show');
    trigger.setAttribute('aria-expanded', 'true');

    const icon = trigger.querySelector('.icon-rotate');
    if (icon) {
      icon.classList.add('rotated');
    }
  }

  function collapseSection(section, trigger) {
    section.classList.remove('show');
    trigger.setAttribute('aria-expanded', 'false');

    const icon = trigger.querySelector('.icon-rotate');
    if (icon) {
      icon.classList.remove('rotated');
    }
  }

  function closeOtherSections(currentSection, parentSelector) {
    const parentElement = document.querySelector(parentSelector);
    if (parentElement) {
      parentElement.querySelectorAll('.collapse.show').forEach((section) => {
        if (section !== currentSection) {
          const trigger = parentElement.querySelector('[data-bs-target="#' + section.id + '"]');
          if (trigger) {
            collapseSection(section, trigger);
          }
        }
      });
    }
  }

  function setBackButtonAttributes(level, start, end) {
    const backButton = document.querySelector('.js-back-button');
    if (backButton) {
      backButton.setAttribute('data-level', level);
      backButton.setAttribute('data-start', start);
      backButton.setAttribute('data-end', end);
      backButton.classList.remove('toggle-btn');
    }
  }

  function toggleLogoVisibility(show) {
    const logoImg = document.querySelector('.js-navbar-logo-img');
    if (logoImg) {
      logoImg.classList.toggle('d-none', !show);
    }
  }

  function handleMainItemClick() {
    const activeChildSection = document.querySelector('.child-items-section.active');
    const activeContentSection = document.querySelector('.content-section.active');

    if (activeChildSection) {
      setBackButtonAttributes('2', null, '2');
    } else if (activeContentSection) {
      setBackButtonAttributes('3', null, '3');
    }
  }

  function handleNavItemClick() {
    const activeLevel = document.querySelector('.level.active');
    if (activeLevel) {
      setBackButtonAttributes('1', '1', '1');
      toggleLogoVisibility(false);
      navFooter.classList.add('d-none');
    }
  }

  function handleBackButtonClick() {
    const backButton = document.querySelector('.js-back-button');
    const dataLevel = backButton.getAttribute('data-level');
    const dataEnd = backButton.getAttribute('data-end');

    if (dataLevel === dataEnd) {
      const selectors = {
        1: '.level.active',
        2: '.child-items-section.active',
        3: '.content-section.active',
      };

      const selector = selectors[dataLevel];
      const activeElement = document.querySelector(selector);

      if (activeElement) {
        activeElement.classList.remove('active');

        if (dataLevel === '1') {
          toggleLogoVisibility(true);
          backButton.classList.add('toggle-btn');
          navFooter.classList.remove('d-none');
        } else if (dataLevel === '2') {
          setBackButtonAttributes('1', null, '1');
          navFooter.classList.add('d-none');
        } else if (dataLevel === '3') {
          setBackButtonAttributes('1', null, '1');
          navFooter.classList.add('d-none');
        }
      }
    }
  }

  function handleMenuToggleClick() {
    const selectors = ['.level.active', '.child-items-section.active', '.content-section.active'];
    selectors.forEach(selector => {
      document.querySelectorAll(selector).forEach(element => {
        element.classList.remove('active');
      });
    });
    setBackButtonAttributes(null, null, '0');
    toggleLogoVisibility(true);
    const backButton = document.querySelector('.js-back-button');
    if (backButton) {
      backButton.classList.add('toggle-btn');
    }
  }

  document.querySelectorAll('.js-navbar-nav-item').forEach((item) => {
    item.addEventListener('click', handleNavItemClick);
  });

  document.querySelectorAll('.main-item').forEach((item) => {
    item.addEventListener('click', handleMainItemClick);
  });

  document.querySelector('.js-back-button').addEventListener('click', handleBackButtonClick);
  document.querySelector('.js-navbar-menu-toggle').addEventListener('click', handleMenuToggleClick);
}

function navStyle() {
  if (!isNavbarDark) {
    navbarStyle.classList.remove('navbar-dark');
  }

  if (isNavbarDark) {
    // navbarStyle.classList.add('navbar-dark');
  }
}

function toggleClass(element, className) {
  element.classList.contains(className)
    ? element.classList.remove(className)
    : element.classList.add(className);
}

function handleMobileMenu() {
  toggleClass(document.documentElement, 'open-navbar-full-mobile');
  toggleClass(navbarMain, 'navbar-main-mobile-open');
}

navbarMenuToggle.forEach((item) => {
  item.addEventListener('click', () => {
    handleMobileMenu();
  });
});

function handleShowMenu() {
  if (isDesktopView) {
    requestAnimationFrame(() => {
      $BODY.classList.add('open-navbar-full');
      navbarStyle.classList.add('navbar-dark');
    });
  }
}

function handleHideMenu() {
  $BODY.classList.remove('open-navbar-full');
  
  if (isDesktopView && !navbarStyle.classList.contains('navbar-dark-active')) {
    navbarStyle.classList.remove('navbar-dark');
  }
}

dropdownNavFull.addEventListener('click', function () {
  if (dropdownNavFull.classList.contains('open')) {
    handleHideMenu();  
    dropdownNavFull.classList.remove('open');
  } else {
    dropdownNavFull.classList.add('open');
  }
});

// Check if the navbar is fixed
function isNavbarFixedActive() {
  if (isNavbarFixed) {
    return isNavbarFixed.classList.contains('navbar-fixed');
  }
}

window.onload = function () {
  navStyle();
};

// Component: Language selector
function toggleLanguageSelector() {

   // Check if elements exits to avoid error if is missing
  if(!$languageSelectorWrapper || !$btnToggleLang) {
    return false;
  }

  // Toggle language switcher
  $btnToggleLang.addEventListener('click', function(e) {
    $languageSelectorWrapper.classList.toggle('opened');
    if (isDesktopView) {
      handleHideMenu();  
      closeOtherDropdowns(null);
    }
    e.stopPropagation()
  });

  // Hide component when click out of language switcher
  document.addEventListener('click', function(e) {
    if(e.target.closest('.js-language-selector')) return;
    $languageSelectorWrapper.classList.remove('opened');

  })
}

toggleLanguageSelector();
